import React, {useEffect} from "react";


function App() {

    useEffect(() => {
        document.title = "Passeio"; // Set the tab title
    }, []);

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };

    const textStyle = {
        fontSize: '2rem',
        fontWeight: 'bold',
        animation: 'blink 0.5s infinite',
    };

    const keyframes = `
    @keyframes blink {
      0% {
        color: white;
      }
      50% {
        color: black;
      }
      100% {
        color: white;
      }
    }
  `;


    return (
        <div style={containerStyle}>
            <style>{keyframes}</style>
            <div style={textStyle}>
                PASSEIO PAU NA TUA CARA OH BÓRRO
            </div>
        </div>
    );

}

export default App;
